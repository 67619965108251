import React, { useEffect, useState } from "react"

import Grid from "@material-ui/core/Grid"
import getStripe, { StripeElementsOptions } from "../../../utils/stripejs"
import { Elements } from "@stripe/react-stripe-js"
import { CardContent, Card } from "@material-ui/core"
import { UserType } from "../Dashboard"
import { formatCurrencyString, formatDate } from "../../../utils/format"

import { SubscriptionDetail } from "./SubscriptionDetail"
import { useLazyApi } from "../../../hooks/useLazyApi"
import { Button } from "../../ui/Button"
import { isTrialAvailable, trialDaysLeftText } from "../../../utils/memberships"

interface LineItem {
  id: string
  description: string
  currency: string
  quantity: number
  unitAmount: number
  lineType:
    | "product_none_shippable"
    | "sales_tax"
    | "subtotal"
    | "total"
    | "product_shippable"
  taxRate?: number
}

interface Invoice {
  id: string
  created: {
    formatted: string
  }
  periodStart: {
    formatted: string
  }
  periodEnd: {
    formatted: string
  }
  status: string
  total: number
}

export type SubscriptionProvider = "google" | "apple" | "stripe" | "unknown"

export interface SubscriptionData {
  id: string
  paymentIntentId: string
  status: string // "active", "inactive", "trialing"
  provider: SubscriptionProvider
  currentPeriodEnd: {
    formatted: string
  }
  paidUntilDate: {
    formatted: string
  }
  subscriptionId: string
  cancelAtPeriodEnd: boolean
  lineItems: LineItem[]
  created: {
    formatted: string
  }
  confirmationCode: string
  user: UserType
  invoices: Invoice[]
  providerPaymentMethodId: string
  trialDaysOnHeadsetConnect?: number
  headsetConnectedTrialActivated?: boolean
  discountAmount?: number
  couponCode?: string
}

interface MembershipData {
  id: string
  status: "active" | "inactive" | "trialing"
}

interface SubscriptionsProps {
  user: UserType
  showHistory?: boolean
  membership?: MembershipData
}

const SubscriptionsControl = ({ user, showHistory }: SubscriptionsProps) => {
  const [
    listPaymentMethods,
    {
      loading: pmtMethodsLoading,
      error: pmtMethodsError,
      data: pmtMethodsData,
      errMsg: pmtMethodsMsg,
    },
  ] = useLazyApi("billing", "ListPaymentMethods")

  useEffect(() => {
    listPaymentMethods()
  }, [])

  const buildNoMembership = (showSub?: SubscriptionData) => {
    let cnt = <></>
    let trial = false
    if (user?.membership?.status === "active") {
      return cnt
    }

    if (isTrialAvailable(user)) {
      trial = true
      cnt = (
        <>
          <p>{trialDaysLeftText(user)}</p>
        </>
      )
    } else {
      cnt = (
        <>
          <p>{trialDaysLeftText(user)}</p>
        </>
      )
    }

    return (
      <Card style={{ marginTop: "1rem" }}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {cnt}
              <Button
                type="primary"
                link={[{ url: "/store/membership" }]}
                title={
                  isTrialAvailable(user)
                    ? "Activate Membership"
                    : "Become a Member"
                }
                sectionId="user_dash"
                id={
                  isTrialAvailable(user)
                    ? "activate_trial_membership"
                    : "new_membership"
                }
                size="regular"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  const activeSubscription = user?.membership?.subscription
  const currentSub = activeSubscription || undefined
  const showSub = activeSubscription ?? currentSub
  const expiredSubscriptions =
    user?.expiredMemberships.length > 0
      ? user.expiredMemberships.map(m => m.expiredSubscription)
      : []
  const history = !showHistory ? [] : expiredSubscriptions

  return (
    <>
      {!showHistory && buildNoMembership(showSub)}
      {showSub && !showHistory && (
        <Card style={{ marginTop: "1rem" }}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SubscriptionDetail
                  subscription={showSub}
                  membershipStatus={user?.membership?.status}
                  pmtMethods={pmtMethodsData?.ListPaymentMethods}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      {showHistory &&
        history.length > 0 &&
        history.map(hs => (
          <Card style={{ marginTop: "1rem" }}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SubscriptionDetail
                    subscription={hs}
                    pmtMethods={pmtMethodsData?.ListPaymentMethods}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
    </>
  )
}

const Subscriptions = (props: SubscriptionsProps) => (
  <Elements stripe={getStripe()} options={StripeElementsOptions as any}>
    <SubscriptionsControl user={props.user} showHistory={props.showHistory} />
  </Elements>
)

export default Subscriptions
