import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import { CartProduct, Product } from "../../../ts/interfaces"
import { getStripeId } from "../../../utils/stripejs"
import { useCheckoutState } from "../../../checkout/state"
import { useProducts } from "../../../hooks/useProducts"

const ThumbImage = styled(props => <GatsbyImage {...props} />)`
  width: 125px;
  height: 125px;
  margin-right: 1.5em;
`

const ProductImg = ({ cartProd }: { cartProd: CartProduct }) => {
  const { currency } = useCheckoutState()

  if (cartProd === null || cartProd === undefined) {
    return <div />
  }
  const price = cartProd.prices[currency]

  if (price === null) {
    return <div />
  }

  const products = useProducts()

  const product: Product = products.filter((prd: Product) => {
    const stripeId = getStripeId(prd.stripeId, prd.stripeTestId)
    return stripeId === cartProd.id
  })[0]

  return (
    <ThumbImage
      image={
        product &&
        product.featuredImage &&
        product.featuredImage.imageFile &&
        product.featuredImage.imageFile.childImageSharp.gatsbyImageData
      }
      objectFit="contain"
      objectPosition="50% 50%"
      alt={
        product &&
        product.featuredImage &&
        product.featuredImage.alternativeText
      }
    />
  )
}

export default ProductImg
